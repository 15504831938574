//修改HTML
export const CHANGE_ABOUT_HTML="CHANGE_ABOUT_HTML"

//修改博主技能点
export const CHANGE_SKILLS="CHANGE_SKILLS"


//修改位置信息
export const CHANGE_POSITION="CHANGE_POSITION"

//修改ip地址
export const CHANGE_IP="CHANGE_IP"

//修改时间
export const CHANGE_TIME="CHANGE_TIME"