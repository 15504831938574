export const GET_RECORD_LIST = "GET_RECORD_LIST"

export const SET_PREVIEW_URL = "SET_PREVIEW_URL"

export const SET_SHOW_PREVIEW_CPNS = "SET_SHOW_PREVIEW_CPNS"

export const SET_CURRENT_IMG_INDEX = "SET_CURRENT_IMG_INDEX"

export const SET_IS_FETCHING_DATA = "SET_IS_FETCHING_DATA"

export const SET_PRE_IMG_LIST_COUNT = "SET_PRE_IMG_LIST_COUNT"