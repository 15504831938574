import styled from "styled-components";

export const PositionWrap = styled.div `
span{
  color:${props=>props.homeFontColor}
}
padding:15px;
.YourInfo{
  font-size:14px;
  font-family:"楷体";
  color:#858585;
  padding-top:10px;
}
.your_words{
  font-size:17px;
  border-radius:4px;  
  border-bottom:1px solid  #209d7b;
}
`;